// uncomment for development only, turns on HMR
// import "/src/sass/app.scss";

import $ from "jquery";
import 'lightbox2';
import initMenuFunctions from "./menus";
import initSliders from "./sliders";
import initUtilities from "./utils";
import initAnimations from "./animation";


$(document).ready(function () {
  // initialize menu functions
  initMenuFunctions();

  // initialize slider carousels
  initSliders();

  // initialize the utility functions
  initUtilities();

  // initialize the animation functions
  initAnimations();

});

import $ from 'jquery'

export const initMenuFunctions = () => {

    // set the mobile menu hamburger icon
    $(document).on('click', '.hamburger', function () {
        $(this).toggleClass('is-active');
        $('.mobile-menu').toggleClass('hidden');

        if ($('body').hasClass('is-home')) {
            $('body').toggleClass('overflow-hidden');
            $('.hero-block').toggleClass('mobile-display');
            $('.mobile-menu').toggleClass('bg-opacity-20');
        }
    });

    // set menu bg color to transparent if not scrolled
    if ($(window).scrollTop() !== 0) {
        $('header').removeClass('lg:bg-opacity-95');
    }
    $(window).scroll(function () {
        let st = $(this).scrollTop();
        if (st > 10) {
            $("header").addClass("bg-bblue");
            $("header").removeClass("lg:bg-opacity-95");

        } else {
            $("header").removeClass("bg-bblue");
            $("header").addClass("lg:bg-opacity-95");
        }
    });

    
    $(document).ready(function() {
        if ($(window).scrollTop() !== 0) {
            $("header").addClass("bg-bblue");
        }
    });

    $('.parent-nav').each(function() {
        const $parentNav = $(this);
        const $parentLink = $parentNav.find('a').first();
        const $childrenNav = $parentNav.find('.children-nav');

        const toggleSubmenu = (isExpanded) => {
            $childrenNav.toggleClass('hidden');
            $parentNav.attr('aria-expanded', !isExpanded);
            $childrenNav.attr('aria-hidden', isExpanded);
        };

        const closeAllSubmenus = () => {
            $('.parent-nav').not($parentNav).each(function() {
                const $otherChildrenNav = $(this).find('.children-nav');
                if ($otherChildrenNav.length) {
                    $otherChildrenNav.addClass('hidden');
                    $(this).attr('aria-expanded', false);
                    $otherChildrenNav.attr('aria-hidden', true);
                }
            });
        };

        $parentNav.on('keydown', function(event) {
            if ((event.key === 'Enter' || event.keyCode === 13) && $(event.target).is($parentLink)) {
                if ($childrenNav.length) {
                    event.preventDefault();
                    const isExpanded = $parentNav.attr('aria-expanded') === 'true';
                    closeAllSubmenus();
                    toggleSubmenu(isExpanded);
                }
            }

            if ((event.key === 'Escape' || event.keyCode === 27) && $childrenNav.length && !$childrenNav.hasClass('hidden')) {
                $childrenNav.addClass('hidden');
                $parentNav.attr('aria-expanded', false);
                $childrenNav.attr('aria-hidden', true);
            }
        });

        $parentLink.on('click', function(event) {
            if ($childrenNav.length) {
                event.preventDefault();
            }
        });
    });





};

export default initMenuFunctions;
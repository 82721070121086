import AOS from "aos";

export const initAnimations = () => {
  initAOS();
};

function initAOS() {
  AOS.init({
    offset: 100,
    duration: 1000,
    delay: 100,
    once: true,
    // disable: true
    disable: {
      mobile: true,
      desktop: window.matchMedia('(prefers-reduced-motion: reduce)').matches
    }
  });
}

export default initAnimations;
